import React, { useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
// // redux mapping
import { useDispatch, useSelector } from "react-redux";
import {
  fetchApps,
  fetchUsers,
  saveQuery,
  deleteApp,
  saveUsersApp,
} from "../../redux/reducers/store-reducer";
import {
  cleanPreview,
  setGroups,
} from "../../redux/reducers/store-detail-reducer";
import { useNavigate, useLocation } from "react-router-dom";
import PageStore from "../../Components/Page/PageStore";
import Page401 from "../Pages/Page401";
import {
  Row,
  Col,
  Divider,
  Typography,
  Empty,
  Result,
  notification,
  Modal,
  Form,
  Select,
} from "antd";
import Store from "./Components/Store";
import FilterStore from "./Components/FilterStore";
import { SmileOutlined } from "@ant-design/icons";
import { useTranslate } from "../../Translate";
import {
  openNotificationWithIcon,
  isManagement_app,
  isAccess_app,
} from "../../Helpers/Helpers";
import { sort } from "semver";
const { confirm } = Modal;
const { Option } = Select;
const { Title, Paragraph, Text, Link } = Typography;

const Apps = (props) => {
  const [formModalRef] = Form.useForm();
  const { search } = useLocation();
  //gestione della lingua
  const i18n = useTranslate();
  const { t } = i18n;
  const { vista } = props;
  const { keycloak } = useKeycloak();
  const userid = keycloak?.tokenParsed?.sub?.toString();
  const navigate = useNavigate();
  // set up dispatch
  const dispatch = useDispatch();
  // fetch data from our store
  const {
    loading,
    error,
    apps,
    category,
    users,
    query: lastSearch,
  } = useSelector((state) => state.apps);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [maxUsers, setMaxUsers] = useState(0);
  const [profiloIntegrazione, setProfiloIntegrazione] = useState(null);
  const [utentiAbilitati, setUtentiAbilitati] = useState([]);
  const [reload, setReload] = useState(false);
  const [accountId, setAccountId] = useState(null);
  const [appId, setAppId] = useState(null);
  const [query, setQuery] = useState(lastSearch.query || "");
  const [catsearch, setCatsearch] = useState(lastSearch.category || "");
  const [pathsearch, setPathsearch] = useState(lastSearch.path !=="?fieldSearch=&" ?  lastSearch.path : "?fieldSearch=&orderBy=dataPubblicazione&orderType=desc");
  const [sortsearch, setSortsearch] = useState(lastSearch.sort || "");
  const [users_children, setUsers_children] = useState([]);
  const [optionsSelected, setOptionsSelected] = useState([]);
  useEffect(() => {
    if (vista === 'view_myapps') {
      dispatch(fetchApps(keycloak.token, userid, '', vista));
    }else{
      dispatch(fetchApps(keycloak.token, userid, pathsearch, vista));
    }

  }, [vista, reload]);

  //hook to fetch items
  useEffect(() => {
    getNotice(error);
  }, [error]);

  const handleDetail = (id) => {
    dispatch(cleanPreview());
    navigate("/store/" + id);
  };

  const getNotice = (response) => {
    if (response.status) {
      if (response.code == 400 || response.code == 404) {
        openNotificationWithIcon(
          "warning",
          "Warning",
          <>{response.message ? response.message : ""}</>
        );
      } else if (response.code == 401) {
        openNotificationWithIcon(
          "warning",
          "Warning",
          response.message ? response.message : ""
        );
      } else {
        openNotificationWithIcon(
          "error",
          "Errore",
          t("errorapp") + "\n" + response.message ? response.message : ""
        );
      }
    } else {
      if (
        response.code === 200 &&
        vista === "view_myapps" &&
        response.message !== ""
      ) {
        var msg = t("msgsaveuserapp");
        openNotificationWithIcon("success", "Success", msg);
        setReload(!reload);
        navigate("/myservices");
      }
    }
  };
  const handleGoApp = (app) => {
    if (app.profiloIntegrazione === 2) {
      //downloadfile
      //passo l'id dell'app
      navigate("/myservices/" + app.appId + "/documents");
    } else if (app.profiloIntegrazione ===5) {
      //in questo caso devo passare l'id dell'acquisto
      navigate("/myservices/"+ app.appId +"@"+ app.id + "/cs/documents");
    }else if(parseInt(app.profiloIntegrazione) ===6){//profilo d'integrazione dedicato ai serviz di nv group
      navigate("/myservices/" + app.id + "/shipments");
    }else if (app.profiloIntegrazione === 1) {
      //zenshare
      var baseLink = app.linkApp;
      var lastChar = baseLink.slice(-1);
      if (lastChar !== "/") {
        baseLink = baseLink + "/";
      }
      var codide_azienda = "loginKC.php?codice_azienda=" + app.codiceAzienda;
      var link = baseLink + codide_azienda;
      window.location.href = link;
    } else {
      window.location.href = app.linkApp;
    }
  };

  const handleDeleteApp = (app) => {
    confirm({
      title: t("questiondeleteservice"),
      onOk() {
        dispatch(
          deleteApp(keycloak.token, userid, app.appId, app.accountId, app.id)
        );
      },
    });
  };

  const handleChangeUSers = (value) => {
    console.log(`selected ${value}`);
    setOptionsSelected(value);
  };

  //hook to fetch items
  useEffect(() => {
    //popolo la lookup degli utenti
    console.log(optionsSelected);
    var lk_users = [];
    users.map((user) => {
      lk_users.push(
        <Option
          value={user.id}
          key={user.id}
          disabled={optionsSelected.length > parseInt(maxUsers) || (utentiAbilitati.includes(user.id) && parseInt(profiloIntegrazione) ===7)}
        >
          {user.firstName + " " + user.lastName}
        </Option>
      );
    });
    setUsers_children(lk_users);
  }, [users, maxUsers, optionsSelected]);

  const handleSettingApp = (app) => {
    formModalRef.setFieldsValue({
      users: app.utentiAbilitati,
    });
    console.log(app.utentiAbilitati);
    setMaxUsers(app.maxUtenti);
    setAppId(app.appId);
    setAccountId(app.accountId);
    setProfiloIntegrazione(app.profiloIntegrazione);
    setUtentiAbilitati(app.utentiAbilitati);
    dispatch(fetchUsers(keycloak.token, app.accountId));
    showModal();
  };

  useEffect(() => {
    const data = {
      category: catsearch,
      query: search,
    };
    fetchAppsFilter("search", data);
  }, [search]);

  const handleChangeSorting = (values) => {
    setSortsearch(values);
    const data = {
      category: catsearch,
      sort: values === 1 ? "desc" : "asc",
      query: query, //provvisorio solo per utilizzare la var
    };
    fetchAppsFilter("search", data);
  };
  const handleChangeCategory = (values) => {
    setCatsearch(values);
    const data = {
      category: values,
      sort: sortsearch,
      query: query, //provvisorio solo per utilizzare la var
    };
    fetchAppsFilter("search", data);
  };

  const handleClearFilter = () => {
    setQuery("");
    setCatsearch("");
    setPathsearch("");
    setSortsearch("");
    dispatch(fetchApps(keycloak.token, userid, "", vista));
    dispatch(
      saveQuery({
        path: "",
        category: "",
        sort: "",
        query: "",
      })
    );
  };

  const fetchAppsFilter = (type, data) => {
    let apiUrl = "";
    //devo filtrare le app in base alla ricerca
    if (type === "search") {
    
      data.query ? (apiUrl += data.query + "&") : (apiUrl += "?fieldSearch=&");
      data.category ? (apiUrl += `categoria=${data.category}`) : (apiUrl += "");
      data.sort
        ? (apiUrl += "orderBy=dataPubblicazione&orderType=" + data.sort)
        : (apiUrl += "");
      
     
    }
    //setto il default
    if( apiUrl === "?fieldSearch=&"){
      apiUrl="?fieldSearch=&orderBy=dataPubblicazione&orderType=desc";
    }
    setPathsearch(apiUrl);
    dispatch(fetchApps(keycloak.token, userid, `${apiUrl}`, vista));
    dispatch(
      saveQuery({
        path: ` ${apiUrl}`.trim(),
        category: data?.category,
        query: data?.query,
      })
    );
  };

  const showModal = () => {
    //formModalRef.resetFields();
    setIsModalOpen(true);
  };

  const handleOk = () => {
    formModalRef
      .validateFields()
      .then((data) => {
        setIsModalOpen(false);
        modalSaveDataUsers(data);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const modalSaveDataUsers = (data) => {
    console.log(data);
    data["addedUserId"] = data["users"];
    // Array per salvare gli elementi trovati
    var dataUser = [];
    var dataZen = [];
    delete data["users"];
    data["appId"] = appId;
    data["groupId"] = accountId;
    const app = apps?.content?.filter((obj) => {
      return obj.appId === appId;
    });
    if (app[0].profiloIntegrazione === 1) {
      // Primo ciclo forEach per iterare sull'array di oggetti
      users.forEach((user) => {
        // Secondo ciclo forEach per iterare sugli elementi da cercare
        data["addedUserId"].forEach((i) => {
          // Controllo se gli oggetti corrispondono
          if (user.id === i) {
            // Elemento trovato, lo aggiungo all'array degli elementi trovati
            dataUser.push(user);
          }
        });
      });
      dataZen["users"] = dataUser;
      dataZen["codiceAzienda"] = app[0].codiceAzienda;
      dataZen["linkApp"] = app[0].linkApp;
    }
    console.log(dataUser);
    dispatch(saveUsersApp(keycloak.token, data, dataZen));
  };

  console.log(apps);

  return vista === "view_myapps" && !isAccess_app(keycloak) ? (
    <Page401></Page401>
  ) : (
    <PageStore loading={loading} inner>
      {vista === "view_store" ? (
        <Row gutter={[0, 0]} style={{ border: "0px solid" }}>
          <Col span={7}>
            <Title level={2} style={{ color: "#8c8b8b" }}>
              Dai nuovo impulso al tuo lavoro.
            </Title>
          </Col>
          <Col span={14}>
            <Paragraph style={{ fontSize: "18px" }}>
              Toshiba mette a tua disposizione la sua tecnologia per dare un impulso al tuo lavoro quotidiano. Accedi al tuo account per scoprire come!
              <br></br>
              <Text style={{ color: "#f24c46" }}>
                {" "}
                Non ne hai ancora uno?{" "}
                <Link href="/registration">Crea il tuo account.</Link>
              </Text>
            </Paragraph>
          </Col>
        </Row>
      ) : (
        ""
      )}
      {vista === "view_store" ? (
        <Row style={{ border: "0px solid", marginTop: "0px" }}>
          <Col offset={19} style={{ border: "0px solid" }}>
            <FilterStore
              handleChangeCategory={handleChangeCategory}
              catsearch={catsearch}
              category={category}
              sortsearch={sortsearch}
              handleChangeSorting={handleChangeSorting}
              handleClearFilter={handleClearFilter}
            />
          </Col>
        </Row>
      ) : (
        ""
      )}
      <Divider
        orientation="left"
        style={{ color: "#e61e1e", fontSize: "20px", marginTop: "-10px" }}
      >
        {vista === "view_store"
          ? "Store"
          : vista === "view_myapps"
          ? t("myapps")
          : t("myfavorites")}
      </Divider>
      {apps?.length > 0 || apps?.content?.length > 0 ? (
        <>
          <Store
            apps={apps}
            tipo={vista}
            handleDetail={handleDetail}
            handleSettingApp={handleSettingApp}
            handleGoApp={handleGoApp}
            handleDeleteApp={handleDeleteApp}
            handleChangeCategory={handleChangeCategory}
            catsearch={catsearch}
            category={category}
            isManagement_app={isManagement_app(keycloak)}
            isAccess_app={isAccess_app(keycloak)}
          />
        </>
      ) : apps?.content?.length === 0 ? (
        <Empty />
      ) : (
        <Result status="warning" title="Items not available at this time." />
      )}
      <Modal
        title={t("settingapp")}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={t("save")}
      >
        <Paragraph style={{ fontSize: "15px", color: "#8c8b8b" }}>
          {t("numbermaxuser")}: {maxUsers}
        </Paragraph>
        <Form
          form={formModalRef}
          name="modal-ref"
          layout="vertical"
          scrollToFirstError
        >
          <Form.Item name="users" label={t("users")}>
            <Select
              mode="multiple"
            
              clearIcon={parseInt(profiloIntegrazione) === 7 ? false : true}
              removeIcon={ parseInt(profiloIntegrazione) === 7 ? null : undefined }
              style={{ width: "100%" }}
              showSearch
              onChange={handleChangeUSers}
              placeholder={t("selectusers")}
              optionFilterProp="children"
              filterOption={(input, option) => option.children.includes(input)}
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {users_children}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </PageStore>
  );
};

export default Apps;
